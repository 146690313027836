import Vue from 'vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config'
import { cloneDeep } from 'lodash-es'

// Plugins
import HttpPlugin from '@advisr/frontend-ui-components/src/plugins/HttpPlugin'
import PermissionPlugin from './plugins/Permission'
import FormattersPlugin from './plugins/Formatters'
import ClonePlugin from './plugins/Clone'
import ImageUtils from './plugins/ImageUtils'
import Async from './plugins/Async'
import ValidationPlugin from './plugins/Validation'
import CurrencyFormatter from '@advisr/frontend-ui-components/src/plugins/CurrencyFormatter'
import ErrorTracking from './plugins/ErrorTracking'
import Vue2TouchEvents from 'vue2-touch-events'
import GlobalEventsPlugin from './plugins/GlobalEvents'
import ApiPlugin from './plugins/Api'
import SocketsPlugin from './plugins/Sockets'
import FileUtilPlugin from './plugins/FileUtils'

// Mixins
import MessagingUtils from './mixins/MessagingUtils'
import branding from './mixins/branding'

// Global components
import GlobalComponents from './components/global'

// CSS imports
import '@advisr/frontend-ui-components/src/assets/global.css'
import '@advisr/ux-framework/src/assets/styles/colors.css'
import './assets/styles/variables.css'
import './assets/styles/global.css'
import './assets/styles/transitions.css'
import './assets/styles/utility.css'

// Register vue plugins
Vue.use(PermissionPlugin)
Vue.use(FormattersPlugin)
Vue.use(ClonePlugin)
Vue.use(ImageUtils)
Vue.use(ValidationPlugin)
Vue.use(CurrencyFormatter)
Vue.use(Async)
Vue.use(Vue2TouchEvents)
Vue.use(ApiPlugin)
Vue.use(GlobalEventsPlugin)
Vue.use(SocketsPlugin)
Vue.use(FileUtilPlugin)

Vue.use(HttpPlugin, {
  responseInterceptor (response) {
    return response
  },
  async responseErrorInterceptor (error) {
    if (error.code === 'ECONNABORTED') {
      if (
        error.config.url.indexOf('/version.json') === -1 &&
        error.config.url.indexOf('/user-notification/unread-count') === -1
      ) {
        store.commit('ADD_CONNECTION_ERROR_MESSAGE')

        const requestClone = cloneDeep(error.config)
        if (requestClone.headers && requestClone.headers.map && requestClone.headers.map.token) {
          delete requestClone.headers.map.token
        }

        Vue.$errorTracking.logError(
          new ErrorTracking.customErrors.ConnectionError(new Error(), error.config.url),
          {
            request: requestClone,
            response: error.response
          }
        )
      }

      const newError = new Error(error.message)
      newError.body = {
        message: error.message
      }
      newError.code = 0

      return Promise.reject(newError)
    }

    const { response } = error

    if (
      config.api &&
      config.api.apiRoot &&
      response &&
      response.config.url.indexOf(config.api.apiRoot) >= 0
    ) {
      // Response is from the app API
      // Check for generic errors
      if (response.status === 403) {
        store.commit('ADD_ERROR_MESSAGE', 'You do not have permission to this functionality. If you believe you should, please contact support.')
      } else if (response.status === 401) {
        if (response.data && response.data.message === 'No active company group selected') {
          if (router.currentRoute.name !== 'SwitchGroup') {
            router.push({ name: 'SwitchGroup', query: { path: router.currentRoute.path } })
              // Ignore error of canceled navigation
              .catch(() => {})
          }
        } else if (response.data && response.data.message === 'Authentication token has expired') {
          if (router.currentRoute.name !== 'Login') {
            store.commit('ADD_INFO_MESSAGE', 'You have been automatically logged out. Please login again.')
            await store.dispatch('logout')
            router.push({ name: 'Login', query: { path: router.currentRoute.path } })
              // Ignore error of canceled navigation
              .catch(() => {})
          }
        } else {
          if (router?.history?.current?.name && router.history.current.name !== 'Login') {
            store.commit('ADD_ERROR_MESSAGE', 'You are not authorized. Please login again.')
            await store.dispatch('logout')
            router.push({ name: 'Login', query: { path: router.currentRoute.path } })
              // Ignore error of canceled navigation
              .catch(() => {})
          }
        }
      } else if (response.status === 503) {
        // App is currently in maintence mode... refresh
        location.reload()
      }
    }

    return Promise.reject(error)
  }
})

Vue.use(ErrorTracking, config.rollbar)

// Register Global Mixins
Vue.mixin(MessagingUtils)
Vue.mixin(branding)

// Register global components
for (const component in GlobalComponents) {
  Vue.component(component, GlobalComponents[component])
}

// Analytics
const useAnalytics = (
  config.google &&
  config.google.analytics &&
  config.google.analytics.apiKey &&
  config.google.analytics.apiKey !== ''
)

if (useAnalytics) {
  Vue.use(VueGtag, {
    config: { id: config.google.analytics.apiKey },
    pageTrackerTemplate (to) {
      return {
        page_title: `App: ${to.name}`,
        page_path: to.path,
        page_location: window.location.href
      }
    }
  }, router)
}

Vue.config.productionTip = false

new Vue({
  beforeCreate () {
    // Pass all socket events to global event bus
    this.$socket.addCatchAllListener(this.$globalEvent.emit)
  },
  beforeDestroy () {
    // Remove all catch all listeners
    this.$socket.removeCatchAllListeners()
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
